<template>
  <v-container>
    <v-row
      align="center"
      justify="center">
      <v-col v-if="!isLoggedIn" xs="12" sm="10" md="8" xl="4">
        <h1>Anmelden</h1>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <v-form v-model="valid" action="POST" @submit.prevent="loginUser">
                    <v-row>
                      <v-col>
                        <v-text-field
                          name="email"
                          label="E-Mail"
                          id="email"
                          type="email"
                          :rules="emailRules"
                          autocomplete="username"
                          v-model="authDetails.identifier"
                          required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          name="password"
                          label="Passwort"
                          id="password"
                          type="password"
                          :rules="passRules"
                          autocomplete="current-password"
                          v-model="authDetails.password"
                          required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn color="accent" type="submit">Anmelden</v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-btn text color="secondary" to="/request-password">Passwort vergessen</v-btn>
                      </v-col>
                    </v-row>
                    <v-col v-if="error" class="text-xs-center">
                      <v-alert
                        type="error"
                      >{{error}}</v-alert>
                    </v-col>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import rules from '../../helpers/rules';

const LOGIN_USER = gql`
mutation login ($identifier: String! $password: String! ){
  login(input:{identifier: $identifier password: $password}){
    jwt,
    user{
      id,
      role{
        name
      }
    }
  }
}
`

export default {
  name: 'Login',
  valid: false,
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: null,
      authDetails: {
        identifier: '',
        password: ''
      },
      loading: 0,
      error: null,
      passRules: rules.passRules,
      emailRules: rules.emailRules,
    }
  },
  methods: {
    loginUser: function () {
      this.$apollo.mutate({mutation: LOGIN_USER, variables: { ...this.authDetails }}).then((data) => {
        const token = 'Bearer ' + data.data.login.jwt;
        
        
        localStorage.setItem('token', token); //store JWT to local storage
        // localStorage.setItem('user', String(data.data.login.user.id));
        localStorage.setItem('role', data.data.login.user.role.name);
        this.error = null; //clear error, if login failed previusly
        this.$emit('changeLoginState', token); //trigger login change event on App to change local login state
        //set route for roles
        if (data.data.login.user.role.name === 'spieler') {
          this.$router.push({path: '/spieler'}); // go to spieler home
        } else if (data.data.login.user.role.name === 'verein') {
          this.$router.push({path: '/verein'}); // go to verein home
        } else if (data.data.login.user.role.name === 'referee') {
          this.$router.push({path: '/'}); // go to verein home
        } else if (data.data.login.user.role.name === 'scoring') {
          this.$router.push({path: '/alle-wettspiele-scoring'}); // go to verein home
        } else {
          this.$router.push({path: '/vereine'}); // go to home
        }

      }).catch((error) => {
        // Error
        console.error(error);
        if(error.message.indexOf("Identifier or password invalid.") !== -1){
          this.error = "Benutzername oder Passwort nicht gültig."
        }else{
          this.error = "Benutzername oder Passwort nicht gültig.";
        }

        localStorage.setItem('token', '');
        this.$emit('changeLoginState');
      });
    }
  }
}
</script>
